<template>
  <div>
    <TopNavBar2></TopNavBar2>
    <thank1></thank1>
    <Footer1></Footer1>
  </div>
</template>

<script>
import TopNavBar2 from '../components/TopNavBar2.vue'
import thank1 from '../components/thank1.vue'
import Footer1 from '../components/footer.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/thank-you-quote' }
      ]
    }
  },
  data() {
    return {
      metaData: {

        en: {
          Homepage: {
            title: 'Thank you for your request | Pennsylvania Logistics', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'description',
                'content': 'Our team has received your request, and we’ll get back to you as soon as possible. ',
              },
              {
                property: 'og:url',
                content: 'https://pennlogisticsllc.com/thank-you-quote',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'Thank you for your request | Pennsylvania Logistics',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'Our team has received your request, and we’ll get back to you as soon as possible.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },


            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/thank-you-quote' }
            ]
          },
        },
      },
    }
  },
  name: 'Home',
  components: {
    TopNavBar2, thank1, Footer1
  },
  computed: {
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  },
}
</script>